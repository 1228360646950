var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mq-layout',{attrs:{"mq":"lg+"}},[_c('div',{staticClass:"desktop-top"},[_c('div',{staticClass:"index",attrs:{"id":"index"}}),_c('div',{staticClass:"wrapper",class:[_vm.isFixed ? 'isFixed' : 'isAbsolute']},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"von"},[_c('img',{attrs:{"src":require("@/assets/images/von.png"),"alt":""}})]),_c('div',{staticClass:"cast"},[_c('img',{attrs:{"src":require("@/assets/images/cast.png"),"alt":""}})]),_c('div',{staticClass:"tt"},[_c('img',{attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}})]),_c('div',{staticClass:"trailer"},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",staticStyle:{"position":"relative"},on:{"click":_vm.ytVideo}},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",style:([
                      _vm.ratio < 1.73
                        ? {
                            width: '40px',
                          }
                        : {
                            width: '70px',
                          } ]),attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}}),_c('div',{staticClass:"trailer-text"},[_vm._v("Trailer*")]),_c('div',{staticClass:"trailer-quelle"},[_vm._v("Quelle: Youtube")])])])]):_vm._e()]),_c('BaseKinostart',{staticClass:"kinostart",attrs:{"kinostart":_vm.kinostart}}),_c('BaseCitesFade')],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"wrapper-right"},[_c('div',{staticClass:"bg",style:([
                _vm.ratio < 2
                  ? {
                      backgroundSize: 'cover',
                    }
                  : {
                      backgroundSize: '100% auto',
                    } ])}),_c('div',{staticClass:"logos",class:_vm.$mq},[_c('img',{staticClass:"w-full h-auto",attrs:{"src":require("@/assets/images/logos.png"),"alt":""}})])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }