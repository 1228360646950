<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mobile-top" id="index">
      <img
        :src="require(`@/assets/images/bg_mobile_${$i18n.locale}.jpg`)"
        alt=""
        @click="openSite"
      />
      <BaseKinostart
        v-if="kinostart != 'Releaseline'"
        class="mobile-top__kinostart"
        :class="$mq"
        :kinostart="kinostart"
      />
      <div
        v-if="showKinofinder"
        class="bg-secondary font-extrabold uppercase w-full text-center cursor-pointer pb-2"
        @click="click"
      >
        Wo läuft der Film?
        <p class="text-primary font-bold text-xs leading-none">Hier drücken</p>
      </div>
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      showKinofinder: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      click() {
        EventBus.$emit('open-startkinos')
      },
      openSite() {
        window.open(
          'https://www.amazon.de/Russland-Von-Oben-Blu-ray/dp/B08LNG4G7W?tag=filmweltverle-21'
        )
      },
    },
  }
</script>

<style lang="scss">
  .mobile-top {
    $self: &;
    position: relative;
    background-color: $primary;
    &__kinostart {
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      padding: 1rem;
      &.md,
      &.lg,
      &.xl,
      &.xxl {
        padding: 1rem 4rem;
      }
    }
  }
</style>
