/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterView from '../views/RouterView.vue'

import { i18n } from '../main.js'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    component: RouterView,
    beforeEnter(to, from, next) {
      const lang = to.params.lang
      if (!['rus', 'de'].includes(lang)) return next('de')
      if (i18n.locale !== lang) {
        i18n.locale = lang
      }
      return next()
    },
    children: [
      {
        path: 'index',
        name: 'index',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "signin" */ '../views/Index.vue'),
      },
      {
        path: 'inhalt',
        name: 'Inhalt',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "signin" */ '../views/Inhalt.vue'),
      },
      {
        path: 'dreharbeiten',
        name: 'Dreharbeiten',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "signin" */ '../views/Dreharbeiten.vue'),
      },
      {
        path: 'signin',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "signin" */ '../views/Signin.vue'),
      },
      {
        path: 'kinos',
        name: 'Kinos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "kinos" */ '../views/Kinos.vue'),
      },
      {
        path: 'mobile',
        name: 'Mobile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "mobile" */ '../components/Mobile.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/de/index',
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store,
  }
  return middleware[0]({
    ...context,
  })
})

export default router
