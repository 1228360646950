<!-- @format -->
<template>
  <div id="fotos" class="base-fotos">
    <div class="bg-fotos">
      <div class="freisteller absolute">
        <img src="@/assets/images/fotos_pic.png" alt="" />
      </div>
      <div class="cite-1 absolute">
        <img src="@/assets/images/cite1.png" alt="" />
      </div>
      <div class="cite-2 absolute">
        <img src="@/assets/images/cite2.png" alt="" />
      </div>

      <div
        v-for="n in 7"
        :key="n"
        :class="`fotos-${n}`"
        class="absolute"
        style="cursor: pointer"
      >
        <img
          :src="require(`@/assets/images/fotos/fotos_${n}.jpg`)"
          alt=""
          @click="openGallery(n)"
        />
      </div>
    </div>
    <BaseModalGallery :index="index" />
  </div>
</template>
<script>
  export default {
    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
      kachel: {
        type: String,
        default: 'fotos_kacheln.jpg',
      },
    },
    data() {
      return {
        index: null,
      }
    },
    methods: {
      openGallery(number) {
        this.index = number - 1
      },
    },
  }
</script>

<style lang="scss">
  .base-fotos {
    position: relative;
    padding: 0 2rem;
    .bg-fotos {
      position: relative;
      background: #fff url($assets + 'images/bg_fotos.jpg') top center no-repeat;
      height: 1929px;
      background-size: cover;
      .absolute {
        position: absolute;
      }
      .freisteller {
        width: 752px;
        height: 905px;
        top: 18px;
        left: 50%;
      }
      .fotos-1 {
        top: 179px;
        left: 50%;
        margin-left: -541px;
      }
      .fotos-2 {
        top: 533px;
        left: 50%;
        margin-left: -22px;
        width: 511px;
        height: 333px;
      }
      .fotos-3 {
        top: 621px;
        left: 50%;
        margin-left: -877px;
      }
      .fotos-4 {
        top: 887px;
        left: 50%;
        margin-left: -18px;
        width: 834px;
        height: 418px;
      }
      .fotos-5 {
        top: 1060px;
        left: 50%;
        margin-left: -765px;
        z-index: 30;
      }
      .fotos-6 {
        top: 1328px;
        left: 50%;
        margin-left: -19px;
        z-index: 29;
        width: 618px;
        height: 333px;
      }
      .fotos-7 {
        top: 1500px;
        left: 50%;
        margin-left: -487px;
      }
      .cite-1 {
        left: 50%;
        top: 53px;
        margin-left: -389px;
      }
      .cite-2 {
        left: 50%;
        top: 1727px;
        margin-left: 115px;
      }
    }
  }
</style>
