<!-- @format -->
<template>
  <div id="fotos" class="base-kachel-fotos">
    <div class="base-kachel-fotos__wrapper" :class="$mq" @click="openGallery">
      <div class="base-kachel-fotos__content" :class="$mq">
        <div class="base-kachel-fotos__bg"></div>
      </div>
      <img
        class="base-kachel-fotos__kachel"
        :src="require(`@/assets/images/` + kachel)"
        alt=""
      />
      <img
        class="base-kachel-fotos__pic"
        :class="$mq"
        src="@/assets/images/fotos_pic_mobile.jpg"
        alt=""
      />
    </div>
    <BaseModalGallery :index="index" />
  </div>
</template>
<script>
  export default {
    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
      kachel: {
        type: String,
        default: 'fotos_kacheln.jpg',
      },
    },
    data() {
      return {
        index: null,
      }
    },
    methods: {
      openGallery() {
        this.index = 1
      },
    },
  }
</script>

<style lang="scss">
  .base-kachel-fotos {
    $self: &;
    &__wrapper {
      position: relative;
      background-color: #fff;
      width: 100%;
      cursor: pointer;
      margin-bottom: 1rem;
      &.md,
      &.lg,
      &.xl,
      &.xxl {
        margin-bottom: 2rem;
      }
      #{$self}__content {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 0 1rem;
        &.md,
        &.lg,
        &.xl,
        &.xxl {
          padding: 0 2rem;
        }
        #{$self}__bg {
          width: 100%;
          height: 100%;
          background-color: #609187;
        }
      }
      #{$self}__kachel {
        position: relative;
        width: 100%;
        height: auto;
        padding: 1.5rem 0;
      }
      #{$self}__pic {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0 1rem;
        &.md,
        &.lg,
        &.xl,
        &.xxl {
          padding: 0 2rem;
        }
      }
    }
  }
  .inhalt-bg {
    background-color: #609187;
  }
</style>
