<!-- @format -->

<template>
  <div class="inhalt-content" :class="$mq">
    <div class="inhalt-text" :class="$mq">
      <div class="inhalt-headline" :class="$mq">
        Ein emotionales Familiendrama
        <mq-layout mq="lg+"><div></div></mq-layout>
        mit feinem Humor ...
      </div>
      <div class="inhalt-pic">
        <img :class="$mq" src="@/assets/images/inhalt_pic.png" alt="" />
      </div>
      <p class="text-top">
        Die französische Filmdiva Fabienne
        <span>(Catherine Deneuve)</span> mimt in ihren Memoiren vortrefflich die
        Rolle der hingebungsvollen, liebenden Mutter. Ihre Tochter Lumir
        <span>(Juliette Binoche)</span> hingegen hat ganz andere Erinnerungen an
        die Kindheit mit einer Frau, die sich stets im Licht der Öffentlichkeit
        sonnte. Als die Drehbuchautorin mit Ehemann Hank<span
          >(Ethan Hawke)</span
        >
        und dem gemeinsamen Kind aus New York nach Paris zurückkehrt, versucht
        sie, ihre Mutter mit den verdrehten Wahrheiten in deren Autobiographie
        zu konfrontieren.
      </p>
      <br />
      <p class="text-bottom">
        Für seine emotionale Familiengeschichte vereint
        <span>Cannes-Gewinner Hirokazu Kore-Eda</span> zum ersten Mal die
        französischen Leinwandlegenden <span>Catherine Deneuve</span> und
        <span>Juliette Binoche</span> vor der Kamera. Ein mit feinem Humor
        erzähltes Drama über Mütter und Töchter, große und kleine Lebenslügen,
        die Kunst der Schauspielerei und den Mut zur Versöhnung.
      </p>
    </div>
    <mq-layout :mq="['sm', 'md']">
      <div class="inhalt-mobile">
        <img src="@/assets/images/inhalt_pic_mobile.jpg" alt="" />
      </div>
    </mq-layout>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .inhalt-content {
    position: relative;
    padding: 0 1rem;
    background-color: $secondary;
    background-size: cover;
    &.md {
      padding: 0 2rem;
    }
    &.lg,
    &.xl,
    &.xxl {
      height: 711px;
      padding: 0;
      background: #fff url($assets + 'images/bg_inhalt.jpg') top center
        no-repeat;
    }
    .inhalt-text {
      text-align: left;
      position: absolute;
      top: 45px;
      left: 50%;
      width: 575px;
      margin-left: -115px;
      span {
        font-weight: 700;
      }
      p.text-top {
        color: white;
      }
      p.text-bottom {
        color: $primary;
      }
      .inhalt-headline {
        color: $tertiary;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
        padding-top: 1.5rem;
        &.md {
          font-size: 1.6rem;
          line-height: 1.7rem;
        }
        &.lg,
        &.xl,
        &.xxl {
          font-size: 1.5rem;
        }
      }
      .inhalt-pic {
        margin-bottom: 1.5rem;
        img {
          &.sm,
          &.md {
            width: 100%;
          }
          &.lg,
          &.xl,
          &.xxl {
            width: auto;
          }
        }
      }
      &.sm,
      &.md {
        position: static;
        margin-left: 0;
        width: 100%;
      }
    }
    .inhalt-mobile {
      width: 100%;
    }
  }
</style>
