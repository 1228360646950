<!-- @format -->
<template>
  <div class="base-navigation">
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div v-for="(value, key) in this.$config.navigation" :key="value.sitename">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <router-link
        v-if="key !== 'Trailer' && key !== 'Fotos' && key !== 'Schulmaterial'"
        :to="'/' + $i18n.locale + '/' + value.sitename"
        class="nav-item"
        :key="value.sitename"
        >{{ $t(`navigation.${key}`) }}</router-link
      >
      <div
        v-if="key === 'Trailer'"
        @click="yt_video"
        class="nav-item open-trailer"
      >
        {{ $t(`navigation.${key}`) }}
      </div>
      <div v-if="key === 'Fotos'" @click="openGallery" class="nav-item">
        {{ $t(`navigation.${key}`) }}
      </div>
      <mq-layout :mq="['sm', 'md']">
        <a
          href="https://www.google.de"
          target="_blank"
          v-if="key === 'Schulmaterial'"
          class="nav-item"
        >
          {{ $t(`navigation.${key}`) }}
        </a>
      </mq-layout>
    </div>
  </div>
</template>

<script>
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  import inView from 'in-view'
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    data() {
      return {
        headerHeight: this.$headerheight,
        duration: 1000,
        easing: 'easeOutCubic',
        easings: Object.keys(easings),
      }
    },
    computed: {
      options() {
        return {
          duration: this.duration,
          offset: this.$headerheight,
          easing: this.easing,
        }
      },
    },
    mounted() {
      this.headerHeight = this.$headerheight

      for (let val in this.$config.navigation) {
        if (val.sitename !== '' && val.sitename !== 'schulmaterial') {
          this.handleView(val.sitename)
        }
      }
    },
    methods: {
      openGallery() {
        EventBus.$emit('open-gallery')
      },
      yt_video() {
        EventBus.$emit('open-yt')
      },
      handleView(item) {
        const linkEl = document.querySelector(`#link-${item}`)

        const offsetHeight = this.$headerheight + 4
        inView.offset({
          top: offsetHeight,
        })

        inView(`#${item}`)
          .on('enter', () => linkEl.classList.add('is-active'))
          .on('exit', (el) => linkEl.classList.remove('is-active'))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-navigation {
    display: flex;
    flex-direction: row;
    .nav-item {
      position: relative;
      text-transform: uppercase;
      height: $header-height;
      line-height: $header-height;
      padding-right: $header-link-padding;
      color: $header-link;
      font-weight: $header-fontweight;
      cursor: pointer;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 12px;
      $max_font: $header-fontsize;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      &:hover {
        color: $header-link-hover;
      }
    }
  }
  .is-active {
    font-weight: 900;
  }
</style>
