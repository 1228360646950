<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="$mq">
      <div class="header__content">
        <nav class="header__language" @click="onLangChange">
          <div class="header__language-text">{{ $t('language.text') }}</div>
          <div class="header__language-flag" :class="$mq">
            <img :src="flags" alt="" />
          </div>
        </nav>
        <div class="header__main-navi">
          <nav id="navi">
            <BaseRouterLinkNavigation />
          </nav>
          <nav v-if="kinofinder" id="finder">
            <BaseKinofinder v-if="$config.kinoservice.display" />
          </nav>
        </div>
        <component
          :is="companySocial"
          :facebook="true"
          :youtube="false"
          :instagram="false"
          v-if="social"
          class="header__social"
        ></component>
      </div>
    </header>
  </mq-layout>
</template>

<script>
  export default {
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      kinofinder: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      flags() {
        return this.$i18n.locale !== 'rus'
          ? require('@/assets/images/flags/rus.png')
          : require('@/assets/images/flags/de.png')
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
    methods: {
      onLangChange() {
        this.$i18n.locale === 'de'
          ? (this.$i18n.locale = 'rus')
          : (this.$i18n.locale = 'de')
        window.dispatchEvent(new Event('resize'))
      },
    },
  }
</script>

<style lang="scss">
  .header {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: $header-bg;
    z-index: 599;
    overflow: hidden;
    &__content {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: $header-padding;
      height: $header-height;
      #{$self}__main-navi {
        display: flex;
        flex-direction: row;
      }
      #{$self}__social {
        text-align: right;
      }
      #{$self}__language {
        display: flex;
        text-align: left;
        position: relative;
        text-transform: uppercase;
        height: $header-height;
        line-height: $header-height;
        padding-right: $header-link-padding;
        color: $header-link;
        font-weight: $header-fontweight;
        cursor: pointer;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 12px;
        $max_font: $header-fontsize;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        &:hover {
          color: $header-link-hover;
        }
        #{$self}__language-flag {
          margin-left: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          &.lg {
            margin-left: 10px;
            img {
              width: 50%;
            }
          }
        }
      }
    }
  }
</style>
