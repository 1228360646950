/** @format */

const initialState = () => ({
  win: {},
  docElem: null,
})

const state = initialState()

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  setWindow(state, payload) {
    state.win = payload
  },
  setDocElem(state, payload) {
    state.docElem = payload
  },
}

const actions = {
  size({ commit }, { tmp }) {
    commit('setWindow', tmp)
  },
  resize({ commit }) {
    let width = getViewport('x', state)
    let height = getViewport('y', state)
    commit('setWindow', { height: height, width: width })
  },
  docElem({ commit }, { tmp }) {
    commit('setDocElem', tmp)
  },
}

const getters = {
  isSize: (state) => {
    return state.win
  },
  isElem: (state) => {
    return state.docElem
  },
}

function getViewport(axis, state) {
  let client, inner
  if (axis === 'x') {
    client = state.docElem.clientWidth
    inner = window.innerWidth
  } else if (axis === 'y') {
    client = state.docElem.clientHeight
    inner = window.innerHeight
  }
  return client < inner ? inner : client
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
