<!-- @format -->
<template>
  <div id="inhalt" class="base-inhalt" :class="$mq">
    <div
      v-if="mouseanimation"
      class="mouse"
      :class="{ 'mouse-fixed': inhaltOut }"
    >
      <div class="mouse-wrapper">
        <img src="@/assets/images/mouse.png" alt="" />
        <div class="mouse-ani">
          <img src="@/assets/images/mouse_arrow.png" alt="" />
        </div>
      </div>
    </div>
    <div v-waypoint="{ active: true, callback: onWaypoint }"></div>
    <inhalt />
  </div>
</template>

<script>
  import Inhalt from '@/components/Content/Inhalt.vue'

  export default {
    components: {
      Inhalt,
    },
    data() {
      return {
        inhaltOut: true,
      }
    },
    props: {
      mouseanimation: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onWaypoint({ going, direction }) {
        if (going === this.$waypointMap.GOING_IN) {
          this.inhaltOut = false
        } else {
          this.inhaltOut = true
        }
      },
    },
  }
</script>

<style lang="scss">
  .base-inhalt {
    position: relative;
    width: 100%;
    padding: 2rem;
    background-color: #fff;
    &.md {
      padding: 2rem;
    }
    .mouse-fixed {
      position: fixed !important;
      top: auto !important;
      bottom: 0 !important;
    }
    .mouse {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: auto;
      top: -63px;
      .mouse-wrapper {
        position: relative;
        height: 95px;
        .mouse-ani {
          position: absolute;
          top: 12px;
          left: 50%;
          transform: translateX(-50%);
          animation: arrow 1s infinite linear;
        }
      }
    }

    @keyframes arrow {
      0% {
        top: 12px;
      }
      50% {
        top: 18px;
      }
      100% {
        top: 12px;
      }
    }
  }
</style>
