<!-- @format -->
<template>
  <div id="partner" class="base-partner">
    <h2 :class="$mq">Unser Partner</h2>
    <div class="base-partner__wrapper" :class="$mq">
      <a
        v-for="value in this.$config.partners"
        :key="value.text"
        :href="value.link"
        target="_blank"
        class="base-partner__items"
        :class="$mq"
        ><div class="base-partner__image">
          <img
            :src="require(`@/assets/images/partner/` + value.img)"
            :alt="value.text"
            :class="$mq"
          />
        </div>
        <div class="base-partner__text" v-text="value.text" />
      </a>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
<style lang="scss">
  .base-partner {
    $self: &;
    position: relative;
    background-color: white;
    h2 {
      color: $tertiary;
      width: 50%;
      margin: 0 auto;
      font-size: 2rem;
      text-align: center;
      padding-top: 2rem;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid $secondary;
      &.lg,
      &.xl,
      &.xxl {
        font-size: 2rem;
      }
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 2rem;
      width: 100%;
      margin: 0 auto;
      &.lg,
      &.xl,
      &.xxl {
        flex-direction: row;
        flex-wrap: wrap;
        width: 66%;
      }
      #{$self}__items {
        width: 100%;
        display: block;
        padding: 1.125rem 0;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.lg,
        &.xl,
        &.xxl {
          width: auto;
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }
        #{$self}__image {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          img {
            width: 75%;
            &.md,
            &.lg,
            &.xl,
            &.xxl {
              width: 100%;
            }
          }
        }
        #{$self}__text {
          height: 2rem;
          text-align: center;
          margin-top: 1rem;
          color: $primary;
        }
      }
    }
  }
</style>
