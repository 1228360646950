<!-- @format -->
<template>
  <div class="base-parallax">
    <vue-parallax
      :intensity="20"
      :image="require('@/assets/images/bg_parallax.jpg')"
    >
    </vue-parallax>
  </div>
</template>

<script>
  import VueParallax from 'vue-js-parallax-component'
  export default { components: { VueParallax } }
</script>

<style lang="scss">
  .base-parallax {
    height: 711px;
    overflow: hidden;
    padding: 0 2rem;
    .parallax__container {
      height: 100% !important;
      .parallax__image {
        margin-top: -300px;
        height: 1684px !important;
      }
    }
  }
</style>
