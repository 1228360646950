<!-- @format -->
<template>
  <div class="base-kinostart">
    <!-- eslint-disable-next-line -->
    <div class="text" :class="$mq" v-html="$t('kinostart.' + kinostart)"></div>
  </div>
</template>

<script>
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
  }
</script>
<style lang="scss">
  .base-kinostart {
    position: relative;
    text-transform: uppercase;
    line-height: 2rem;
    color: white;
    font-weight: 700;
    margin-left: 270px;
    .text {
      &.lg,
      &.xl,
      &.xxl {
        text-align: center;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 24px;
        $max_font: 32px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.sm,
      &.md {
        text-align: center;
        $min_width: 300px;
        $max_width: 1023px;
        $min_font: 22px;
        $max_font: 34px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
    }
  }
</style>
