<!-- @format -->
<template>
  <div class="base-cites-fade">
    <flickity ref="flickity" :options="flickityOptions">
      <div v-for="cite in $config.cites" :key="cite.text" class="carousel-cell">
        <cite class="">
          <p class="text" :class="$mq" v-text="cite.text" />
          <p class="author" v-text="cite.author" />
        </cite>
      </div>
    </flickity>
  </div>
</template>

<script>
  import Flickity from 'vue-flickity'
  require('flickity-fade')

  export default {
    components: {
      Flickity,
    },
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
    data() {
      return {
        flickityOptions: {
          initialIndex: 1,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: true,
          fade: true,
          draggable: false,
        },
      }
    },
  }
</script>
<style lang="scss">
  .base-cites-fade {
    width: 100%;
    .carousel-cell {
      left: 50% !important;
      transform: translateX(-50%) !important;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      .text {
        font-style: normal;
        font-size: 1rem;
        color: $secondary;
        font-weight: 700;
        &.md,
        &.xl,
        &.xxl {
          font-size: 1.25rem;
        }
      }
      .author {
        color: $secondary;
        text-transform: uppercase;
        font-style: normal;
        font-size: 1rem;
        font-weight: 300;
        margin-top: 0.25rem;
      }
    }

    .flickity-viewport {
      height: 100px !important;
    }

    .flickity-slider {
      transform: translateX(0) !important;
    }
  }
</style>
