<!-- @format -->

<template>
  <div id="app" class="app">
    <mq-layout mq="lg+">
      <div
        :style="[
          ratio < 1.98
            ? {
                height: $store.getters['Window/isSize'].height + 'px',
                width: $store.getters['Window/isSize'].height * 1.98 + 'px',
              }
            : {
                width: $store.getters['Window/isSize'].width + 'px',
                height: $store.getters['Window/isSize'].width / 1.98 + 'px',
              },
        ]"
        class="app__bg"
      >
        <video
          src="@/assets/videos/sizzle.mp4"
          poster="@/assets/images/bg.jpg"
          autoplay
          muted
          loop
        ></video>
        <div class="overlay"></div>
      </div>
      <DesktopHeader :social="true" :kinofinder="true" />
    </mq-layout>
    <router-view />
    <mq-layout mq="lg+">
      <BaseYoutube :youtube="$config.youtube" :margin="100" />
      <BaseModalGallery :index="num" />
    </mq-layout>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    data() {
      return {
        num: this.index,
      }
    },
    props: {
      index: {
        type: Number,
        default: null,
      },
    },
    computed: {
      local() {
        return process.env.NODE_ENV
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.$store.dispatch('Window/docElem', {
          tmp: window.document.documentElement,
        })
        window.addEventListener('resize', this.sendDispatch)
        this.$store.dispatch('Window/resize')
        window.dispatchEvent(new Event('resize'))
        EventBus.$on(
          'open-gallery',
          function () {
            this.num = 0
          }.bind(this)
        )
        EventBus.$on(
          'close-gallery',
          function () {
            this.num = null
          }.bind(this)
        )
      })
    },
    methods: {
      sendDispatch(e) {
        this.$store.dispatch('Window/resize')
        this.isMobile()
      },
      isMobile() {
        if (this.$store.getters['Window/isSize'].width < 1023) {
          this.$router.currentRoute.path === '/' + this.$i18n.locale + '/mobile'
            ? ''
            : this.$router.push('/' + this.$i18n.locale + '/mobile')
        } else {
          this.$router.push('/' + this.$i18n.locale + '/index')
        }
      },
    },
    head: {
      title: function () {
        return {
          inner: this.$config.title,
        }
      },
      meta: function () {
        return [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width,initial-scale=1' },
          {
            property: 'og:title',
            content: this.$config.title,
            template: (chunk) => `${chunk} - Offizielle Webseite`,
            hid: 'og:title',
          },
          {
            property: 'og:url',
            content: this.$config.url,
            hid: 'og:url',
          },
          {
            property: 'og:description',
            content: this.$config.facebook.og_description,
            hid: 'og:description',
          },
          {
            property: 'og:image',
            content: this.$config.facebook.og_image,
            hid: 'og:image',
          },
          {
            property: 'keywords',
            content: this.$config.keywords,
          },
          {
            property: 'page-topic',
            content: 'Medien',
          },
          {
            property: 'robots',
            content: 'index, follow',
          },
          {
            property: 'author',
            content: this.$config.verantwortlich,
          },
          {
            property: 'publisher',
            content: this.$config.verantwortlich,
          },
          {
            property: 'copyright',
            content: this.$config.verantwortlich,
          },
          {
            property: 'description',
            content: this.$config.facebook.og_description,
            hid: 'description',
          },
        ]
      },
      link: function () {
        return [
          { rel: 'icon', type: 'image/x-icon', href: this.$config.favicon },
        ]
      },
    },
  }
</script>

<style lang="scss">
  .app {
    $self: &;
    &__bg {
      overflow: hidden;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      .overlay {
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: transparent url($assets + 'images/overlay.png') top left
          repeat;
      }
      video {
        // height: 100%;
        width: 100%;
      }
    }
  }

  #app {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    .dev {
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 15px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 10px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 0;
      .console {
        padding: 0 20px;
        color: red;
        background-color: black;
      }
    }
  }
</style>
