<!-- @format -->

<template>
  <footer class="footer">
    <div class="footer__content">
      <!-- <div class="footer__tt">
        <img
          :class="$mq"
          :src="require(`@/assets/images/` + $config.tt_small)"
          alt="Titelimage"
        />
      </div> -->

      <!-- eslint-disable -->
      <div
        v-if="kinostart !== 'Releaseline'"
        class="footer__kinostart"
        :class="$mq"
        v-html="$t('kinostart.' + kinostart)"
      ></div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>

      <mq-layout mq="lg+">
        <div
          v-if="youtubenote && $config.youtube.id"
          class="footer__yt-note"
          :class="$mq"
        >
          * Zum Ansehen des Videos müssen Sie auf “Trailer” klicken. Wir möchten
          Sie darauf hinweisen, dass nach der Aktivierung Daten an Youtube
          übermittelt werden.
        </div>
      </mq-layout>
      <div v-if="billing" class="footer__billing">
        <img src="@/assets/images/billing.png" alt="Billing" :class="$mq" />
      </div>
    </div>
    <component
      :is="companySocial"
      class="footer__social"
      :class="$mq"
    ></component>
    <div v-if="$config.verantwortlich == 'Prokino'" class="footer__media-logo">
      <img class="inline-block" src="@/assets/images/media_logo.png" alt />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    background-color: $primary;
    padding-bottom: 4rem;
    &__content {
      position: relative;
      margin: 0 auto;
      padding-top: 2rem;
      #{$self}__tt {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1rem;
        img {
          width: 75%;
          margin: 0 auto;
          &.md {
            width: 60%;
          }
          &.lg,
          &.xl,
          &.xxl {
            width: 20%;
          }
        }
      }
      #{$self}__kinostart {
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 1rem;
        &.lg,
        &.xl,
        &.xxl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 24px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 22px;
          $max_font: 34px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
      #{$self}__yt-note {
        color: white;
        margin: 0 auto;
        font-size: 0.7rem;
        padding: 0 2rem;
        padding-bottom: 1rem;
        line-height: 1;
        &.md {
          font-size: 0.875rem;
        }
        &.lg,
        &.xl,
        &.xxl {
          width: 50%;
          padding-right: 0;
          padding-left: 0;
        }
      }
      #{$self}__billing {
        text-align: center;
        width: 100%;
        img {
          width: 100%;
          &.sm,
          &.md {
            width: 70%;
          }
          &.lg,
          &.xl,
          &.xxl {
            width: auto;
          }
        }
      }
    }
    &__social {
      color: white;
      &.lg,
      &.xl,
      &.xxl {
        margin-top: 1rem;
      }
    }
    &__media-logo {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 1.5rem;
    }
  }
</style>
