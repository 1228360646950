var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",attrs:{"id":"app"}},[_c('mq-layout',{attrs:{"mq":"lg+"}},[_c('div',{staticClass:"app__bg",style:([
        _vm.ratio < 1.98
          ? {
              height: _vm.$store.getters['Window/isSize'].height + 'px',
              width: _vm.$store.getters['Window/isSize'].height * 1.98 + 'px',
            }
          : {
              width: _vm.$store.getters['Window/isSize'].width + 'px',
              height: _vm.$store.getters['Window/isSize'].width / 1.98 + 'px',
            } ])},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"poster":require("@/assets/images/bg.jpg"),"autoplay":"","muted":"","loop":""},domProps:{"muted":true}}),_c('div',{staticClass:"overlay"})]),_c('DesktopHeader',{attrs:{"social":true,"kinofinder":true}})],1),_c('router-view'),_c('mq-layout',{attrs:{"mq":"lg+"}},[_c('BaseYoutube',{attrs:{"youtube":_vm.$config.youtube,"margin":100}}),_c('BaseModalGallery',{attrs:{"index":_vm.num}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }