<!-- @format -->
<template>
  <div class="base-kinofinder" v-if="$config.kinoservice.display">
    <ul>
      <!-- <li>
        <a
          v-if="$i18n.locale === 'de'"
          :href="$config.kinoservice.finder + $config.kinoservice.slug"
          target="_blank"
          >{{ $t('kinoservice.Kinofinder') }}</a
        >
        <a
          v-else-if="$i18n.locale === 'rus'"
          href="https://kinokartina.tv/movie/polyet-nad-rossiey/"
          target="_blank"
          >{{ $t('kinoservice.Kinofinder') }}</a
        >
      </li>
      <li v-if="$config.kinoservice.alert_text !== '' && $i18n.locale === 'de'">
        <a
          :href="$config.kinoservice.alert + $config.kinoservice.id"
          target="_blank"
          >{{ $t('kinoservice.Filmwecker') }}</a
        >
      </li> -->
      <li class="share" v-if="$config.kinoservice.share !== ''">
        <a href="#" @mouseover="hover = true" @mouseleave="hover = false">{{
          $t('kinoservice.Teilen')
        }}</a>
        <div
          class="share__btns"
          :class="{ hovered: hover }"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <a
            target="_blank"
            :href="`https://www.facebook.com/sharer/sharer.php?u=${$config.url}`"
            class="share__item share__fb"
            ><img src="@/assets/images/social/fb_plain.png" alt="" />
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${$config.twitter.text}`"
            target="_blank"
            class="share__item share__twitter"
          >
            <img src="@/assets/images/social/twitter_plain.png" alt="" />
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hover: false,
      }
    },
  }
</script>

<style lang="scss">
  .base-kinofinder {
    position: relative;
    height: $header-height;
    ul {
      text-align: center;
      list-style-type: none;
      line-height: $header-lineheight;
      display: flex;

      .hovered {
        transform: translateY(-$header-height);
      }

      .share {
        position: relative;
        &__btns {
          position: absolute;
          padding-left: 10px;
          right: -100px;
          bottom: -$header-height;
          height: $header-height;
          display: flex;
          flex-direction: row;
          transition: transform 0.3s ease-in-out;
          width: 100px;
          .share__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .share__fb {
            background-color: $facebook;
            &:hover {
              background-color: darken($facebook, 5%);
            }
          }
          .share__twitter {
            background-color: $twitter;
            &:hover {
              background-color: darken($twitter, 5%);
            }
          }
        }
      }
      li {
        height: $header-height;
        display: inline-block;
        text-align: left;
        a {
          text-decoration: none;
          text-transform: uppercase;
          height: $header-height;
          line-height: $header-height;
          color: $header-link;
          font-weight: $header-fontweight;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 12px;
          $max_font: $header-fontsize;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          &:hover {
            color: $header-link-hover;
          }
        }
        &:not(:last-child) {
          padding-right: $header-link-padding;
        }
      }
    }
  }
</style>
