<!-- @format -->
<template>
  <div class="modal-gallery" :class="$mq">
    <gallery
      v-if="$config.gallery.display"
      :images="images"
      :index="index"
      :class="$mq"
      @close="close"
    />
  </div>
</template>

<script>
  import VueGallery from 'vue-gallery'
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    components: {
      gallery: VueGallery,
    },
    props: {
      index: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        images: [],
      }
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 2) +
          `.jpg`)
      }
    },
    methods: {
      close() {
        EventBus.$emit('close-gallery')
      },
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>

<style lang="scss">
  .modal-gallery {
    #blueimp-gallery {
      border: 30px solid $primary;
      &.sm,
      &.md {
        border: none;
        .slides {
          .slide {
            img {
              min-width: 0;
              min-height: 0;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      &.lg {
        .slides {
          .slide {
            img {
              min-width: 0;
              min-height: 0;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .slides {
        .slide {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%;
            max-width: none;
            max-height: none;
          }
        }
      }
      .prev {
        width: 50px;
        height: 100px;
        padding-bottom: 10px;
        margin-top: -50px;
        font-family: 'Roboto';
        font-size: 60px;
        font-weight: 900;
        line-height: 100px;
        color: #000;
        background: #fff;
        border: none;
        border-radius: 0;
        opacity: 1;
        left: 0;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
      .next {
        width: 50px;
        height: 100px;
        padding-bottom: 10px;
        margin-top: -50px;
        font-family: 'Roboto';
        font-size: 60px;
        font-weight: 900;
        line-height: 100px;
        color: #000;
        background: #fff;
        border: none;
        border-radius: 0;
        opacity: 1;
        right: 0;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
      .close {
        padding: 15px;
        right: 15px;
        font-size: 60px;
        font-weight: 900;
        color: #000;
        background-color: #fff;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: #f00;
        }
      }
    }
  }
</style>
