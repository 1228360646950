<!-- @format -->
<template>
  <div>
    <div class="m-nav">
      <a id="nTrigger" class="m-nav__trigger" :class="$mq" @click="openMenu">
        <i></i><i></i><i></i>
      </a>
      <nav class="m-nav__language" @click="onLangChange">
        <div class="m-nav__language-text">{{ $t('language.text') }}</div>
        <div class="m-nav__language-flag">
          <img :src="flags" alt="" />
        </div>
      </nav>
    </div>
    <div class="offcanvas-bar" :class="{ 'show-offcanvas': open }">
      <window-close @click="closeMenu" class="close" :class="$mq" />
      <div class="wrapper">
        <template v-for="(value, key) in this.$config.navigation">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <div
            v-if="key !== 'Trailer'"
            class="nav-item"
            :class="$mq"
            v-scroll-to="{ el: '#' + value.sitename, offset: -50 }"
            @click="closeMenu()"
          >
            {{ $t(`navigation.${key}`) }}
          </div>
        </template>
        <component
          :is="companySocial"
          v-if="social"
          class="pr-4 text-3xl"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  import WindowClose from 'vue-material-design-icons/WindowClose.vue'

  export default {
    components: {
      WindowClose,
    },
    props: {
      social: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        open: false,
        duration: 1000,
        easing: 'easeOutCubic',
        easings: Object.keys(easings),
      }
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
      flags() {
        return this.$i18n.locale !== 'rus'
          ? require('@/assets/images/flags/rus.png')
          : require('@/assets/images/flags/de.png')
      },
    },
    methods: {
      openMenu() {
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      closeMenu() {
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      onLangChange() {
        this.$i18n.locale === 'de'
          ? (this.$i18n.locale = 'rus')
          : (this.$i18n.locale = 'de')
        window.dispatchEvent(new Event('resize'))
      },
    },
  }
</script>

<style lang="scss">
  .show-offcanvas {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
  .offcanvas-bar {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $tertiary;
    padding: 1.5rem;
    width: 50%;
    transform: translatex(-100%);
    -webkit-transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    .close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 5rem;
      width: 5rem;
      height: 5rem;
      color: #000;
      background: #fff;
      border-radius: 100%;
      svg {
        margin-bottom: 9px;
      }
      &.sm {
        font-size: 3rem;
        width: 3rem;
        height: 3rem;
        svg {
          margin-bottom: 6px;
        }
      }
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      .nav-item {
        position: relative;
        text-transform: uppercase;
        font-size: 2.25rem;
        color: #fff;
        font-weight: 700;
        margin: 15px 0;
        &.sm {
          font-size: 1.2rem;
          margin: 5px 0;
        }
      }
    }
  }
  .m-nav {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: $header-height;
    background-color: $primary;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    $self: &;
    &__language {
      display: flex;
      text-transform: uppercase;
      height: $header-height;
      line-height: $header-height;
      padding-right: $header-link-padding;
      color: $header-link;
      font-weight: $header-fontweight;
      cursor: pointer;
      $min_width: 300px;
      $max_width: 1023px;
      $min_font: 20px;
      $max_font: $header-fontsize;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      &:hover {
        color: $header-link-hover;
      }
      #{$self}__language-flag {
        margin-left: 20px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    &__trigger {
      cursor: pointer;
      width: $header-height;
      height: $header-height;
      padding: 10px;
      background-color: $primary;
      animation: outAll 0.8s forwards;
      i {
        background-color: #fff;
        border-radius: 2px;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
      }
      i:nth-child(1) {
        -webkit-animation: outT 0.8s backwards;
        animation: outT 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
      }
      i:nth-child(2) {
        margin: 5px 0;
        -webkit-animation: outM 0.8s backwards;
        animation: outM 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
      }
      i:nth-child(3) {
        -webkit-animation: outBtm 0.8s backwards;
        animation: outBtm 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
      }
      &.active {
        animation: inAll 0.8s forwards;
        i:nth-child(1) {
          -webkit-animation: inT 0.8s forwards;
          animation: inT 0.8s forwards;
        }
        i:nth-child(2) {
          -webkit-animation: inM 0.8s forwards;
          animation: inM 0.8s forwards;
        }
        i:nth-child(3) {
          -webkit-animation: inBtm 0.8s forwards;
          animation: inBtm 0.8s forwards;
        }
      }
    }
  }

  @keyframes inAll {
    0% {
      background-color: $primary;
    }
    100% {
      background-color: transparent;
    }
  }
  @keyframes outAll {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: $primary;
    }
  }

  @keyframes inM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
      background-color: #000;
    }
  }

  @keyframes outM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes inT {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: #fff;
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
      background-color: #000;
    }
  }

  @keyframes outT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }

  @keyframes inBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: #fff;
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
      background-color: #000;
    }
  }

  @keyframes outBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }
</style>
