<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <div class="desktop-top">
      <div id="index" class="index" />
      <!-- NOTE Setzt Top auf fixed, wenn als Property in der App.vue "fixed" übergeben wird, sonst "absolute" z.B. position="fixed" -->
      <div :class="[isFixed ? 'isFixed' : 'isAbsolute']" class="wrapper">
        <div class="content">
          <div class="left">
            <div class="von">
              <img src="@/assets/images/von.png" alt="" />
            </div>
            <div class="cast">
              <img src="@/assets/images/cast.png" alt="" />
            </div>
            <div class="tt">
              <img :src="require(`@/assets/images/` + $config.tt)" alt="" />
            </div>
            <div class="trailer">
              <div v-if="$config.youtube.display">
                <div
                  @click="ytVideo"
                  class="open-trailer"
                  style="position: relative"
                >
                  <video
                    src="@/assets/videos/sizzle.mp4"
                    loop
                    muted
                    autoplay
                  ></video>
                  <div class="trailer-misc">
                    <img
                      :style="[
                        ratio < 1.73
                          ? {
                              width: '40px',
                            }
                          : {
                              width: '70px',
                            },
                      ]"
                      class="play-btn"
                      src="@/assets/images/trailer/play.png"
                      alt=""
                    />
                    <div class="trailer-text">Trailer*</div>
                    <div class="trailer-quelle">Quelle: Youtube</div>
                  </div>
                </div>
              </div>
            </div>
            <BaseKinostart :kinostart="kinostart" class="kinostart" />
            <BaseCitesFade />
          </div>
          <div class="right">
            <div class="wrapper-right">
              <div
                :style="[
                  ratio < 2
                    ? {
                        backgroundSize: 'cover',
                      }
                    : {
                        backgroundSize: '100% auto',
                      },
                ]"
                class="bg"
              ></div>
              <div class="logos" :class="$mq">
                <img
                  class="w-full h-auto"
                  src="@/assets/images/logos.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <BaseStartKinos /> -->
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
      position: {
        type: String,
        default: '',
      },
    },
    computed: {
      isDateorAfter() {
        return new Date() < new Date('2019-12-25')
      },
      isFixed() {
        return this.position === 'fixed'
      },
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      click() {
        EventBus.$emit('open-startkinos')
      },
    },
  }
</script>

<style lang="scss">
  .isFixed {
    position: fixed;
  }
  .isAbsolute {
    position: absolute;
  }
  .desktop-top {
    position: relative;
    .index {
      position: relative;
      height: 100vh;
    }
    .wrapper {
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100vh;
      width: 100%;
      .content {
        display: flex;
        flex-direction: row;
        padding: 5rem 2rem 2rem 2rem;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .left {
          position: relative;
          width: 33%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 4rem;
          padding-left: 2rem;
          .von {
            margin-bottom: 1.5rem;
            margin-top: 0.5rem;
          }
          .cast {
            margin-bottom: 2rem;
          }
          .tt {
            margin-bottom: 1.5rem;
            width: 80%;
            img {
              width: 100%;
            }
          }
          .trailer {
            position: relative;
            width: 100%;
            margin-bottom: 1.5rem;
            cursor: pointer;
            .trailer-misc {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 111;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              .trailer-text {
                font-weight: 600;
                color: white;
                font-size: 24px;
                text-transform: uppercase;
                margin-top: 0.5rem;
              }
              .trailer-quelle {
                font-size: 12px;
                color: white;
                line-height: 1;
              }
            }
          }
        }
        .right {
          position: relative;
          width: 66%;
          .wrapper-right {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            .bg {
              width: 100%;
              height: 100%;
              background: #fff url($assets + 'images/bg.jpg') top left no-repeat;
            }
            .logos {
              position: absolute;
              bottom: 0;
              right: 0;
              margin-right: 2rem;
              margin-bottom: 2rem;
              width: 6rem;
              &.xl,
              &.xxl {
                margin-right: 3rem;
                margin-bottom: 3rem;
                width: 8rem;
              }
            }
          }
        }
      }
    }
    .kinostart {
      margin-bottom: 1.5rem;
    }
  }
</style>
