/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueWaypoint from 'vue-waypoint'
import VueScrollTo from 'vue-scrollto'
import VueHead from 'vue-head'
import VueI18n from 'vue-i18n'
import messages from './lang'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'vue-material-design-icons/styles.css'
import 'animate.css/animate.min.css'
import Config from './config/website.config.json'

// Loads Open Sans
require('typeface-open-sans')

// Lädt alle Vue-Dateien, die mit BASE, Desktop oder Mobile anfangen und registriert diese.
require('./plugins/loadglobals')

// https://alligator.io/vuejs/vue-media-queries/
require('./plugins/breakpoints')

require('./plugins/event-bus')

// TODO local DB
//require('./api/index.js')

Vue.config.productionTip = false
// NOTE website.config.js Global in Vue zur Verfügung stellen
Vue.prototype.$config = Config

Vue.use(VueWaypoint)
Vue.use(VueScrollTo)
Vue.use(VueHead)

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages,
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app')

require('./plugins/vuetify')
