/** @format */

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: '#0f0',
        accent: '#0f0',
        error: '#FF5252',
        info: '#2196F3',
        success: '#0f0',
        warning: '#FFC107',
      },
    },
  },
})
